import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface iBackendErrorListProps {
    errors: any;
}

const ErrorHeadline = styled.p`
    font-size: large;
`;

const ErrorBox = styled.div`
    padding: 10px;
    border-radius: 4px;
    ${({ theme }) => css`
        color: ${theme.colors.red.toString()};
        border: 1px solid ${theme.colors.red.toString()};
    `}

    p:first-of-type {
        font-size: large;
    }
`;

const BackendErrorList = ({ errors }: iBackendErrorListProps) => {
    if (!errors) {
        return null;
    }
    return (
        <div>
            {Object.keys(errors).length > 0 && (
                <ErrorBox>
                    <ErrorHeadline>
                        Es sind folgende Fehler aufgetreten. Bitte überprüfe
                        Deine Eingaben und versuche es erneut:
                    </ErrorHeadline>
                    {Object.entries(errors).map(([index, msg]) => (
                        <p key={index}>- {msg}</p>
                    ))}
                </ErrorBox>
            )}
        </div>
    );
};

export default BackendErrorList;
