import React from "react";

import Checkout from "../../../components/pages/Shop/Checkout";
import ShopBaseTemplate from "../../../components/pages/Shop/ShopBaseTemplate";

const ShopBestellungPage = () => {
    return (
        <ShopBaseTemplate>
            <Checkout />
        </ShopBaseTemplate>
    );
};

export default ShopBestellungPage;
