import React, { useEffect } from "react";

import { navigate } from "gatsby";
import { useForm } from "react-hook-form";

import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import { roundNumber } from "../../../../utils/NumberHelpers";
import {
    sessionStorage,
    storageSession,
} from "../../../../utils/StorageHandler";
import resolveWebView, {
    getParentReferrerUrl,
    isWebView,
} from "../../../../utils/webViewRedirect";
import Container from "../../../shared/Container";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import Button, { ButtonColor } from "../../../shared/NewForms/Button";
import OrderBottomBar from "../../../shared/OrderBottomBar";
import OrderStepper from "../../../shared/OrderStepper";
import useSnackbar from "../../../shared/Snackbar/store";
import BackendErrorList from "../components/BackendErrorList";
import useCartStore, { lastVisitedStorePathKey } from "./../ShoppingCart/store";
import StepDeliveryForm from "./StepDeliveryForm";
import StepPaymentMethod from "./StepPaymentMethod";
import StepSummary from "./StepSummary";
import useCheckoutStore, { CheckoutStepEnum, iCheckoutForm } from "./store";

const stepTotalCount = 3;

const StepIndicatorContainer = styled.div`
    margin-bottom: 3em;
`;

const storefrontPath = storageSession.get(lastVisitedStorePathKey, "/");
const Checkout = () => {
    const { notify } = useSnackbar();
    const {
        currentStep,
        setCurrentStep,
        selectedPaymentMethod,
        form,
        setForm,
        createOrder,
        isOrderSubmitting,
        validationErrors,
    } = useCheckoutStore(
        (state) => ({
            currentStep: state.currentStep,
            setCurrentStep: state.setCurrentStep,
            selectedPaymentMethod: state.selectedPaymentMethod,
            form: state.form,
            setForm: state.setForm,
            createOrder: state.createOrder,
            isOrderSubmitting: state.isOrderSubmitting,
            validationErrors: state.backendValidationErrors,
        }),
        shallow
    );
    const { cart, fetchCart } = useCartStore((state) => ({
        cart: state.cart,
        fetchCart: state.fetchCart,
    }));

    //Declare the Main Form here and pass this instance to the children that need to interact with the form.
    const reactHookForm = useForm<iCheckoutForm>({
        defaultValues: form,
    });

    useEffect(() => {
        const parameter = isWebView() ? "Embedded WebView" : "none";
        sessionStorage.setItem("app:parameter", parameter);
        sessionStorage.setItem("app:referrer", getParentReferrerUrl());
    }, []);

    //Abort Checkout if the user cart is empty
    useEffect(() => {
        if (cart && cart.lineItems.length < 1) {
            if (typeof window !== "undefined") {
                void navigate(storefrontPath);
            }
        }
    }, [cart]);

    const handlePaymentStep = async () => {
        if (selectedPaymentMethod) {
            setCurrentStep(CheckoutStepEnum.DELIVERY_FORM);
        }
    };

    const onSubmit = async () => {
        const response = await createOrder(reactHookForm.getValues());
        if (response.order && response.payment) {
            // If an external Payment provider e.g., PayPal is used: Redirect the user to the
            // external provider url, afterward the user is redirected back to our success or error page
            // depending on the payment outcome.
            if (response.payment.redirectUrl) {
                window.location.replace(response.payment.redirectUrl);
                return;
            }
            // Reset checkout and get cart.
            void fetchCart();
            reactHookForm.reset();
            // If payment like Vorkasse is used: Redirect directly to the success page as no further action is required.
            void navigate(
                resolveWebView(
                    `/shop/bestellung/erfolgreich/?orderNumber=${response.order.orderNumber}`
                )
            );
            //Add a bit of the timeout, otherwise the form jumps to the first step before the redirect ist done >_<
            setTimeout(() => {
                setCurrentStep(CheckoutStepEnum.PAYMENT_METHOD);
            }, 1000);
        } else {
            void notify(
                "Fehler",
                "Es ist ein Fehler aufgetreten. Bestellung konnte nicht übertragen werden."
            );
        }
    };

    const handleDeliveryStep = (
        data: iCheckoutForm,
        event: React.BaseSyntheticEvent
    ): void => {
        event.preventDefault();
        setForm(data);
        setCurrentStep(CheckoutStepEnum.SUMMARY);
    };

    return (
        <Container>
            <StepIndicatorContainer>
                <OrderStepper
                    currentStep={currentStep}
                    steps={stepTotalCount}
                />
            </StepIndicatorContainer>
            {currentStep === CheckoutStepEnum.PAYMENT_METHOD && (
                <StepPaymentMethod>
                    <StepperControls
                        onNext={handlePaymentStep}
                        onPrevious={() => navigate(storefrontPath)}
                    />
                </StepPaymentMethod>
            )}

            {currentStep === CheckoutStepEnum.DELIVERY_FORM && (
                <StepDeliveryForm
                    onSubmit={handleDeliveryStep}
                    {...reactHookForm}
                >
                    <StepperControls
                        onPrevious={() =>
                            setCurrentStep(CheckoutStepEnum.PAYMENT_METHOD)
                        }
                    />
                </StepDeliveryForm>
            )}

            {currentStep === CheckoutStepEnum.SUMMARY && (
                <StepSummary onSubmit={onSubmit} {...reactHookForm}>
                    <StepperControls
                        isLoading={isOrderSubmitting}
                        loadingText="Bestellung wird übertragen"
                        nextText="Kostenpflichtig bestellen"
                        onPrevious={() =>
                            setCurrentStep(CheckoutStepEnum.DELIVERY_FORM)
                        }
                    />
                </StepSummary>
            )}

            <BackendErrorList errors={validationErrors} />
            {cart?.price?.totalPrice && (
                <OrderBottomBar
                    leftTextBefore="Gesamtsumme:"
                    price={`${roundNumber(cart.price.totalPrice, 2)} €`}
                    isRightVisible={false}
                />
            )}
        </Container>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 1em;
    justify-content: space-between;
    margin-top: 4em;
    margin-bottom: 5em;
`;

interface iStepperControls {
    onNext?(): void;
    onPrevious?(): void;
    nextText?: string;
    previousText?: string;
    isLoading?: boolean;
    loadingText?: string;
}

const LoadingFlex = styled.div`
    display: flex;
    gap: 1em;
`;

const StepperControls = ({
    onNext = undefined,
    onPrevious = undefined,
    nextText = "Weiter",
    previousText = "Zurück",
    isLoading = false,
    loadingText = "Bitte warten",
}: iStepperControls) => {
    return (
        <ButtonContainer>
            <Button
                type="button"
                onClick={onPrevious}
                color={ButtonColor.redOutline}
                disabled={isLoading}
            >
                {previousText}
            </Button>
            <Button type="submit" onClick={onNext} disabled={isLoading}>
                {isLoading && (
                    <LoadingFlex>
                        {loadingText}
                        <LoadingIndicator light />
                    </LoadingFlex>
                )}
                {!isLoading && nextText}
            </Button>
        </ButtonContainer>
    );
};

export default Checkout;
